import React from "react";
import LandingScreen from "./Screens/LandingScreen";
import "./App.css";
import Navbar from "./Components/Navbar.jsx";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SocialInfo from "./Screens/SocialInfo";
import Projects from "./Screens/Projects";
import End from "./Screens/End";
import AboutUs from "./Screens/AboutUs";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <LandingScreen />
        </Route>
        <Route path="/projects" exact>
          <Projects />
        </Route>
        <Route path="/socials" exact>
          <SocialInfo />
        </Route>
        <Route path="/end" exact>
          <End />
        </Route>
        <Route path="/aboutUs">
          <AboutUs />
        </Route>
      </Switch>
      <Navbar />
    </Router>
  );
}

export default App;
