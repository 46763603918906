import React, { useState, useEffect } from "react";
import "../Styles/Landing.css";
import { useHistory } from "react-router-dom";
import {
  Container,
  Card,
  Title,
  SmallCard,
  Input,
  Button,
  TextArea,
  ErrorModal,
} from "../Components/GlobalComponents";

export default function LandingScreen(props) {
  let history = useHistory();
  const [bio, setBio] = useState("");
  const [name, setName] = useState("");
  function updateLocalStorage() {
    localStorage.setItem(
      "data",
      JSON.stringify({
        name: name,
        about: bio,
      })
    );
  }
  useEffect(() => {
    const already = JSON.parse(localStorage.getItem("data"));
    if (already) {
      if (already.name) {
        setName(val => already.name)
      }
      if (already.about) {
        setBio(val => already.about)
      }
    }
  }, [])
  const [error, setError] = useState(false);

  return (
    <>
      <Container>
        <Card>
          <Title title="Hey! Welcome to Re-Fractor" />
          <SmallCard question="Who are you?">
            <Input
              height={50}
              placeholder={"What do we call you?"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextArea
              height={290}
              value={bio}
              placeholder={"A little description maybe?"}
              onChange={(e) => setBio(e.target.value)}
            />
            <div
              style={{
                marginTop: 30,
              }}
            >
              <Button
                direction="right"
                clickHandler={() => {
                  if (bio.length === 0 || name.length === 0) {
                    setError(true);
                  } else {
                    history.push("/projects");
                    updateLocalStorage();
                  }
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </SmallCard>
        </Card>
      </Container>
      {error ? (
        bio.length === 0 ? (
          <ErrorModal
            error="Please fill your bio"
            props={{ ...props, error, setError }}
          />
        ) : name.length === 0 ? (
          <ErrorModal
            error="Please fill your name"
            props={{ ...props, error, setError }}
          />
        ) : null
      ) : null}
    </>
  );
}
