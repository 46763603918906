import React from "react";
import useWindowDimensions from "./Constants.js";
import "../Styles/Landing.css";
import RightArrow from "../Assets/RightArrow.png";
import { Link } from "react-router-dom";

const Container = (props) => {
  const { width } = useWindowDimensions();
  const container = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: width < 1020 ? "100%" : "100vh",
    justifyContent: "center",
    backgroundColor: "#16171B",
  };
  return <div style={{ ...container, ...props.style }}>{props.children}</div>;
};

const Card = (props) => {
  const { width } = useWindowDimensions();
  const container = {
    alignItems: "center",
    display: "flex",
    backgroundColor: "#16171B",
    flexDirection: "column",
    maxWidth: 1000,
    alignSelf: "center",
    width: "100%",
    borderRadius: 25,
    boxShadow:
      width < 1020
        ? "none"
        : "inset -4px -4px 6px rgba(47, 57, 61, 0.8), inset 4px 4px 6px rgba(0, 0, 0, 0.8)",
    minHeight: 720,
    overflow: "hidden",
    marginTop: width < 1020 ? 0 : 20,
    marginBottom: width < 1020 ? 20 : 20,
  };
  return <div style={{ ...container, ...props.style }}>{props.children}</div>;
};

const Title = (props) => {
  const container = {
    fontSize: 36,
    lineHeight: 1.5,
    textAlign: "left",
    width: "60%",
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#cdcdcd",
    padding: 30,
    minWidth: 300,
  };
  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <h1 style={{ ...container, ...props.style }}>{props.title}</h1>
    </div>
  );
};

const SmallCard = (props) => {
  const container = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    borderTopLeftRadius: 60,
    borderTopRightRadius: 60,
    paddingInline: 30,
    boxShadow: "0px 0px 5px rgba(47, 57, 61, 0.6)",
    flex: 1,
    alignSelf: "flex-end",
  };
  return (
    <div style={{ ...container, ...props.style }}>
      <div
        style={{
          width: 60,
          height: 5,
          borderRadius: 30,
          backgroundColor: "#989898",
          marginBottom: 30,
          marginTop: 25,
        }}
      />
      <h3
        style={{
          color: "white",
          fontSize: 16,
          fontWeight: 200,
          lineHeight: 1.5,
          textAlign: "left",
          width: "100%",
        }}
      >
        {props.question}
      </h3>
      {props.children}
    </div>
  );
};

const Input = ({ height, placeholder, value, setValue, onChange, number }) => {
  return (
    <input
      onChange={onChange}
      value={value}
      id="two"
      placeholder={placeholder}
      autoComplete="off"
      type={number ? number : "text"}
      style={{
        width: "100%",
        border: "none",
        height: height,
        backgroundColor: "#18191d",
        padding: 11,
        fontSize: 18,
        borderRadius: 16,
        marginTop: 20,
        outline: "none",
        color: "#989898",
        resize: "none",
        transitionDelay: 300,
        overflow: "hidden",
        paddingLeft: 15,
      }}
      aria-multiline="true"
    />
  );
};

const TextArea = ({ height, placeholder, value, setValue, onChange }) => {
  return (
    <textarea
      onChange={onChange}
      value={value}
      id="two"
      placeholder={placeholder}
      type="text"
      autoComplete="off"
      style={{
        width: "100%",
        border: "none",
        height: height,
        backgroundColor: "#18191d",
        padding: 11,
        fontSize: 18,
        borderRadius: 16,
        marginTop: 20,
        outline: "none",
        color: "#989898",
        resize: "none",
        transitionDelay: 300,
        overflow: "hidden",
        paddingLeft: 15,
      }}
      aria-multiline="true"
    />
  );
};

const Button = ({ direction, href, clickHandler, marginTop }) => {
  return (
    <div
      style={{
        height: 65,
        width: 65,
        borderRadius: 65,
        backgroundColor: "#16171b",
        boxShadow:
          "-4px -4px 15px rgba(47, 57, 61, 0.6), 4px 4px 15px rgba(0, 0, 0, 0.7),inset 4px 4px  20px rgba(0, 0, 0, 0.7)  ,inset -4px -4px  20px #2F393D",
        //    marginTop: marginTop ? marginTop : 50,
        //    marginBottom: width < 932 ? 30 : 0,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        borderWidth: "2px",
        background: "#16171b",
        cursor: "pointer",
      }}
    >
      <Link
        to={{
          pathname: href,
        }}
        onClick={clickHandler}
      >
        <div
          style={{
            borderWidth: 3,
            background: "#16171b",
            height: 59,
            width: 59,
            borderRadius: 65,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backdropFilter: "blur(20px)",
          }}
        >
          <img
            src={RightArrow}
            alt=""
            style={{
              height: 33,
              width: 33,
              resize: "contain",
              transform: direction === "left" ? "rotate(180deg)" : "",
            }}
          />
        </div>
      </Link>
    </div>
  );
};

const SquareButton = (props) => {
  let { title, height, width, style, onClickHandler } = props;
  return (
    <button
      style={{
        padding: 5,
        paddingInline: 15,
        cursor: "pointer",
        background: "#16171b",
        boxShadow:
          "-4px -4px 7px rgba(47, 57, 61, 0.6), 4px 4px 7px rgba(0, 0, 0, 0.6)",
        borderRadius: 6,
        borderWidth: 0,
        height: height,
        width: width,
        ...style,
      }}
      onClick={onClickHandler}
    >
      <p
        style={{
          color: "white",
          fontSize: 13,
          fontWeight: 200,
          textAlign: "center",
          fontFamily: "Poppins",
        }}
      >
        {title}
      </p>
    </button>
  );
};

const ErrorModal = ({ props, error }) => {
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        backdropFilter: "blur(10px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: 200,
          width: 300,
          color: "#cdcdcd",
          backgroundColor: "rgba(255,255,255, 0.1)",
          background:
            "linear-gradient(139deg, rgba(255,255,255,0.115) 0%, rgba(255,255,255,0.05) 100%)",
          borderRadius: 30,
          border: "solid rgba(255,255,255,0.1)",
          borderWidth: "1px",
          alignSelf: "center",
          backdropFilter: "blur(20px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>{error}</h3>
        <button
          onClick={() => {
            props.setError(false);
          }}
          id="errorButton"
          style={{
            width: "30%",
            height: "12%",
            marginTop: "30px",
            color: "#cdcdcd",
            background: "rgba(255,255,255, 0.1)",
            borderWidth: 0,
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export {
  Container,
  Card,
  Title,
  SmallCard,
  Input,
  Button,
  SquareButton,
  TextArea,
  ErrorModal,
};
