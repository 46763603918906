import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "./Constants";

function Navbar() {
  const { width } = useWindowDimensions();
  return (
    <>
      <nav
        className="navbar"
        style={{
          flexDirection: "row",
          width: "100%",
          height: 90,
          backgroundColor: "#292a2e",
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
          paddingHorizontal: 30,
          paddingInline: 30,
        }}
      >
        <div className="navbar-container">
          <Link
            to="/"
            className="navbar-logo"
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: width < 400 ? "6.79vw" : 28,
              textDecoration: "none",
            }}
          >
            Re-Fractor
          </Link>
        </div>
        <div>
          <a
            href="/aboutUs"
            style={{
              textDecoration: "none",
            }}
          >
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: width < 400 ? "6.79vw" : 28,
                cursor: "pointer",
              }}
            >
              About Us
            </p>
          </a>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
