import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Input,
  SmallCard,
  Title,
  ErrorModal,
} from "../Components/GlobalComponents";
// import { Link } from "react-router-dom";

function SocialInfo() {
  let history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [error, setError] = useState(false);


  function endgame() {
    let old = JSON.parse(localStorage.getItem("data"));

    fetch(
      "https://ap-south-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/club-form-bacnc/service/tryoutdata/incoming_webhook/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...old,
          socials: {
            email: email,
            linkedin: linkedin,
            mobile: phoneNumber,
          },
        }),
      }
    );
  }

  // const hasNumber = /\d/.test(phoneNumber);
  return (
    <>
      <Container>
        <Card>
          <Title
            title={" Where do we reach you?"}
            style={{
              marginTop: 54,
            }}
          />

          <SmallCard question="How can we contact you?">
            <Input
              height={"2.77em"}
              placeholder={"WhatsApp Number"}
              onChange={(e) => setPhoneNumber(e.target.value)}
              number={"number"}
            />
            <Input
              height={"2.77em"}
              placeholder={"Email"}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              height={"2.em"}
              placeholder={"LinkedIn"}
              onChange={(e) => setLinkedin(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "75%",
                alignItems: "center",
                marginTop: 60,
              }}
            >
              <Button
                direction="left"
                marginTop={0}
                clickHandler={() => {
                  history.push("/projects");
                  window.scrollTo(0, 0);
                }}
              />
              <div
                style={{
                  height: "3.16em",
                  width: "7.5em",
                  borderRadius: "0.55em",
                  backgroundColor: "#16171b",
                  boxShadow:
                    "-0.222em -0.222em 0.834em rgba(47, 57, 61, 0.6), 0.222em 0.222em 0.834em rgba(0, 0, 0, 0.7),inset 0.222em 0.222em  1.12em rgba(0, 0, 0, 0.7)  ,inset -0.222em -0.222em  1.1112em #2F393D",
                  //    marginTop: "1em",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderWidth: "0.111em",
                  background: "#16171b",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    if (phoneNumber === "") {
                      setError(true);
                    } else {
                      history.push("/end");
                      window.scrollTo(0, 0);
                      endgame();
                    }
                  }}
                >
                  <div
                    style={{
                      borderWidth: "0.166em",
                      background: "#16171b",
                      height: "3.165em",
                      width: "7.5em",
                      borderRadius: "0.556em",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      backdropFilter: "blur(1.12em)",
                    }}
                  >
                    <p
                      style={{
                        color: "#989898",
                        fontSize: "0.78em",
                      }}
                    >
                      Lets get talking!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SmallCard>
        </Card>
      </Container>
      {error ? (
        phoneNumber.length === 0 ? (
          <ErrorModal
            error="Please fill your number"
            props={{ error, setError }}
          />
        ) : null
      ) : null}
    </>
  );
}

export default SocialInfo;
