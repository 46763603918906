import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Card,
  Container,
  Input,
  SmallCard,
  SquareButton,
  TextArea,
  Title,
} from "../Components/GlobalComponents";

function Projects() {
  let history = useHistory();
  const [projName, setProjName] = useState("");
  const [projDesc, setprojDesc] = useState("");
  const [projLink, setProjLink] = useState("");
  const [projects, setProjects] = useState([]);

  function addProject() {
    setProjects((value) => [
      ...value,
      {
        projectName: projName,
        projectDescription: projDesc,
        projectUrl: projLink,
      },
    ]);
    setProjLink("");
    setProjName("");
    setprojDesc("");
  }
  function updateLocalStorage(e) {
    let old = JSON.parse(localStorage.getItem("data"));
    if (projName || projDesc || projLink) {
      console.log(
        `adding projects bcuase filled and not added to project list`
      );
      localStorage.setItem(
        "data",
        JSON.stringify({
          ...old,
          projects: [
            ...projects,
            {
              projectName: projName,
              projectDescription: projDesc,
              projectUrl: projLink,
            },
          ],
        })
      );
    } else {
      localStorage.setItem(
        "data",
        JSON.stringify({
          ...old,
          projects: projects,
        })
      );
    }
    console.log(`localStorage`, JSON.parse(localStorage.getItem("data")));
  }
  return (
    <Container>
      <Card>
        <Title title="Tell us about your projects" />
        <SmallCard question="What have you done?">
          <Input
            height={50}
            value={projName}
            placeholder={"Name of Project"}
            onChange={(e) => setProjName(e.target.value)}
          />
          <TextArea
            height={150}
            value={projDesc}
            placeholder={"Description"}
            onChange={(e) => setprojDesc(e.target.value)}
          />
          <Input
            height={50}
            value={projLink}
            placeholder={"https://link/to/your/project"}
            onChange={(e) => setProjLink(e.target.value)}
          />
          <SquareButton
            title="+ I have another project!"
            height={31}
            width={250}
            style={{ marginTop: 25 }}
            onClickHandler={addProject}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "75%",
              marginTop: 30,
            }}
          >
            <Button
              direction="left"
              clickHandler={() => {
                history.push("/");
                window.scrollTo(0, 0);
              }}
            />
            <Button
              direction="right"
              clickHandler={() => {
                updateLocalStorage()
                history.push("/socials");
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </SmallCard>
      </Card>
    </Container>
  );
}

export default Projects;
