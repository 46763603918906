import React from "react";
import "../Styles/Landing.css";
import {
  Container,
  Card
} from "../Components/GlobalComponents";

export default function End() {
  return (
    <Container>
      <Card>
        <div
          style={{
            padding: 30,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              color: "#DFDFDF",
              fontSize: 64,
              fontWeight: 300,
              textAlign: "left",
              width: "100%",
              fontFamily: "Poppins",
            }}
          >
            Thanks
          </h3>
          <h3
            style={{
              color: "#DFDFDF",
              fontSize: 36,
              fontWeight: 300,
              textAlign: "left",
              width: "100%",
              fontFamily: "Poppins",
              marginTop: 20,
            }}
          >
            <p>for your interest <br></br> in our <br></br> community!</p>
          </h3>
          <h3
            style={{
              color: "#DFDFDF",
              fontSize: 24,
              fontWeight: 300,
              marginTop: 50,
            }}
          >
            Someone should be on their way to contact you. <br></br> <br></br>{" "}
            <span
              style={{
                color: "#DFDFDF",
                fontSize: 24,
                fontWeight: 300,
                marginTop: 50,
              }}
            >
              Until then pat yourself on <br></br> the back because not <br></br> everyone applies
              :)
            </span>
          </h3>
        </div>
      </Card>
    </Container>
  );
}
